/* You can add global styles to this file, and also import other style files */

/**
 * 🎨---- Colors ----- 🎨
**/

// Primary 🟡
$primary: #ffc947;
$primary-light: #ffde79;
$primary-050: #fcf1cf;

// Secondary 🔵
$secondary: #428ff3;
$secondary-light: #1a6dd8;
$secondary-050: #cce2ff;

// Black ⚫
$black: #1a1918;
$black-op-50: #1a191883;
$black-op-050: #1a191846;
$black-300: #1a1918;
$black-200: #7e8184;
$black-100: #c8c8c8;
$black-050: #f4f4f4;

// Danger 🔴
$danger: #db3645;
$danger-300: #ee5957;
$danger-100: #f28381;
$danger-050: #f7acab;

// White ⚪️
$white: #ffffff;

/**
 * --- FONTS --- 
**/


// * Switzer-ExtraboldItalic
@font-face {
    font-family: 'Switzer-ExtraboldItalic';
    src: url('../src/assets/fonts/switzer/Switzer-ExtraboldItalic.woff2') format('woff2'),
        url('../src/assets/fonts/switzer/Switzer-ExtraboldItalic.woff') format('woff'),
        url('../src/assets/fonts/switzer/Switzer-ExtraboldItalic.ttf') format('truetype');
    font-weight: 800;
    font-display: swap;
    font-style: italic;
}

// * Switzer-VariableItalic
@font-face {
    font-family: 'Switzer-VariableItalic';
    src: url('../src/assets/fonts/switzer/Switzer-VariableItalic.woff2') format('woff2'),
        url('../src/assets/fonts/switzer/Switzer-VariableItalic.woff') format('woff'),
        url('../src/assets/fonts/switzer/Switzer-VariableItalic.ttf') format('truetype');
    font-weight: 600;
    font-display: swap;
    font-style: italic;
}

@import url('https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600;700;800&display=swap');

* {
    margin: 0;
    padding: 0;
    font-family: 'Inter', sans-serif;
}


.strong {
    font-weight: 800;
}

.block {
    background: white;
    padding: 1rem;
    border-radius: 0.5rem;
}



.custom-divider {
    height: 1px;
    width: 100%;
    background-color: $black-050;
    user-select: none;
}

li{
    list-style: none;
}

button {
    margin: 0 !important;
    width: 100%;
    padding: 1rem;
    cursor: pointer;
    border: none;
    border-radius: 0.25rem;
    font-size: 1rem;
    height: 3rem;
    line-height: 1rem;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1rem;

}


.btn-discreet {
    @extend button;
    font-family: 'Switzer-VariableItalic', sans-serif;
    background: none;
    color: $white;
    font-weight: 500;

    &:disabled {
        background: $primary-050 ;
        color: $black-200;
    }

    &:hover:is([disabled]) {
        background: $primary-050;
        color: $black-200;
    }

    &:focus {
        outline: 2px solid transparent !important;
    }

    &:hover {
        background: none;
        font-weight: 900;
    }

    &:active {
        color: $black-050;
        background: #f4f4f420;
    }
}


.btn-primary {
    @extend button;
    font-family: 'Switzer-ExtraboldItalic', sans-serif;
    background: $primary;
    color: $black;

    &:disabled {
        background: $primary-050 ;
        color: $black-200;
    }

    &:hover:is([disabled]) {
        background: $primary-050;
        color: $black-200;
    }

    &:focus {
        outline: 2px solid $primary-light !important;
    }

    &:hover {
        background: $primary-light
    }

    &:active {
        background: $primary-050;
    }
}

.btn-secondary {
    @extend button;
    font-family: 'Inter', sans-serif;
    background: none;
    color: $secondary;
    font-weight: 600;
    font-style: normal !important;
}

.input-wrapper {
    position: relative;
    display: flex;
    flex-direction: row;
    padding-left: 1rem;
    gap: 0.5rem;
}

.alert {
    color: white;
    border-radius: 0.25rem !important;
    padding: 1rem 2rem;
    color: $danger !important;
    background: $danger-050 !important;
    border: 2px solid $danger-300;
}

.gradient {
    background: linear-gradient(transparent, $black);
}


.ng-valid[required],
.ng-valid.required {
    outline: 2px solid $secondary;
}

a {
    color: $secondary;
    text-decoration: none;

    &:hover {
        text-decoration: underline;
    }
}




.input,
.icon-input {


    kin-icon:last-of-type {
        position: absolute;
        transform: translateY(-50%);
        top: 50%;
        z-index: 1;
        right: 16px;
        user-select: none;
        // pointer-events: none;
        z-index: -1;
    }

    input{
        &:focus-visible {
            outline: none;
        }
        border: none ;
    }

    display: flex;
    flex-direction: column;
    border-radius: 0.5rem;

    background: $white;
    position: relative;
    border: 2px solid $black-100;
    padding: 4px 12px;
    height: 60px;

    label {
        font-size: 16px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    kin-icon:first-of-type {
        position: absolute;
        left: 0;
        transform: translateY(-50%);
        top: 50%;
        z-index: 1;
        left: 16px;
    }

    input {
        height: 100%;
        z-index: 2;
        top: 0;
        background: none;
        position: absolute;
        font-size: 16px;
        padding: 0;
        right: 12px;
    }

    .input-header {
        z-index: 1;
        position: absolute;
        transform: translateY(-50%);
        top: 50%;
        display: flex;
        gap: 0.5rem;
        color: $black-100;
        transition: all ease-in 100ms;
    }
}

.input {
    input {
        left: 12px;
    }
}

.icon-input {

    .input-header,
    input {
        left: 52px;
    }
}

.touched {
    .input-header {
        top: 20%;
    }

    .form-error {
        position: absolute;
        bottom: 5%;
        color: $danger;
        font-size: 12px;
        width: -webkit-fill-available;
        margin-right: 16px;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: pre;
    }

    label {
        font-size: 14px;
    }

    input {
        bottom: 23%;
    }
}


.input-error {
    border: 2px solid $danger;
}